import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../../../../appHelpers/axiosInstance";
import { Loader } from "rsuite";
import {
  MainDivider,
  SettingBody,
  SettingHeader,
  ReportContainer,
  ReportTable,
  ReportThead,
  ReportTr,
  ReportTh,
  ReportTd,
  ReportTbody,
} from "../helpers/Admin_StyledComponents";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { EditReport } from "../Customers/Modal/EditReport";
import { DeleteReport } from "../Customers/Modal/DeleteReport";
import { NewReport } from "../Customers/Modal/NewReport";

export const ReportSettingsFC = () => {
  const [update, setUpdate] = useState(false);
  const { id } = useParams(); // GRAB USER ID FROM URL
  const { getAccessTokenSilently } = useAuth0();
  const [reports, setReports] = useState([]);
  const [userSystemMetadata, setUserSystemMetadata] = useState([]);
  const [loadingReports, setLoadingReports] = useState(true);
  const [reportsError, setReportErrors] = useState(false);

  // API CALL TO GET ALL USER REPORTS BY ID
  const getUserReports = async (e) => {
    setLoadingReports(true);
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const res = await axiosInstance({
        method: "GET",
        url: `/user/reports/${id}`,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data);
      setReports(res.data[0]);
      setUserSystemMetadata(res.data[3]);
    } catch (error) {
      console.error(error);
      setReportErrors(true);
    }
    setLoadingReports(false);
  };

  useEffect(() => {
    getUserReports();
  }, [update]);

  return (
    <SettingBody>
      <SettingHeader>Report Settings</SettingHeader>
      <MainDivider />

      {loadingReports && !userSystemMetadata ? (
        <div>Loading...</div>
      ) : (
        <ReportContainer>
          <NewReport
            key={1}
            report={{
              id: null,
              author: id,
              report_name: null,
              systems_list: null,
              field_name: null,
              operator: null,
              threshold: null,
              email_schedule: null,
            }}
            machines={userSystemMetadata}
            update={update}
            setUpdate={setUpdate}
            field_name_tooltip={field_name_tooltip}
          />

          <ReportTable>
            {loadingReports ? (
              <Loader
                content="Loading..."
                style={{
                  padding: "10px",
                  margin: "20px auto",
                }}
              />
            ) : (
              true
            )}
            <ReportThead>
              <ReportTh>Author</ReportTh>
              <ReportTh>Report Name</ReportTh>
              <ReportTh>Field Name</ReportTh>
              <ReportTh>Operator</ReportTh>
              <ReportTh>Edit</ReportTh>
              <ReportTh>Remove</ReportTh>
            </ReportThead>
            <ReportTbody>
              {!reports.length ? (
                <div></div>
              ) : (
                reports.map((report) => (
                  <ReportTr>
                    <ReportTd>{report.author}</ReportTd>
                    <ReportTd>{report.report_name}</ReportTd>
                    <ReportTd>{field_names[report.field_name]}</ReportTd>
                    <ReportTd>{report.operator || "--"}</ReportTd>
                    <ReportTd>
                      <EditReport
                        key={report.id}
                        report={report}
                        machines={userSystemMetadata}
                        update={update}
                        setUpdate={setUpdate}
                        field_name_tooltip={field_name_tooltip}
                      />
                    </ReportTd>
                    <ReportTd>
                      <DeleteReport
                        key={report.id}
                        report={report}
                        update={update}
                        setUpdate={setUpdate}
                      />
                    </ReportTd>
                  </ReportTr>
                ))
              )}
            </ReportTbody>
          </ReportTable>
        </ReportContainer>
      )}
    </SettingBody>
  );
};

const field_names = {
  he_level_value: "Helium Level",
  he_pressure_value: "Helium Pressure",
  all_he_level: "All Helium Levels",
  all_he_psi: "All Helium Pressures",
  he_pressure_72_hr: "72-Hour Helium Pressure",
  shield_temp: "Shield Temperature",
  disabled_alerts: "Disabled Alerts",
};

const field_name_tooltip = [
  {
    label: "Helium Level: conditional",
    value: "he_level_value",
    tooltip:
      "Receive helium level data less than or greater than a specified value.",
  },
  {
    label: "Helium Level",
    value: "all_he_level",
    tooltip:
      "Receive helium level data for all selected systems. No conditions.",
  },
  {
    label: "Helium Pressure: conditional",
    value: "he_pressure_value",
    tooltip:
      "Receive helium pressure data less than or greater than a specified value.",
  },
  {
    label: "Helium Pressure",
    value: "all_he_psi",
    tooltip:
      "Receive helium pressure data for all selected systems. No conditions.",
  },
  {
    label: "72-Hour Helium Pressure",
    value: "he_pressure_72_hr",
    tooltip: "Receive the last 72 hours of min/max helium pressure values.",
  },
  {
    label: "Scan Seconds",
    value: "scan_seconds",
    tooltip: "Receive system and tube scan seconds.",
  },
  {
    label: "Shield Temperature",
    value: "shield_temp",
    tooltip: "Receive shield temperature data.",
  },
  {
    label: "Disabled Alerts",
    value: "disabled_alerts",
    tooltip: "Report provides disabled user alerts.",
  },
];
